import { PositionsInfoData } from './types'
import { usePositionsStore } from '@store/positionsStore'

type PositionsInfoResult = {
  positionsInfoData?: PositionsInfoData
  isLoading: boolean
}

export function usePositionsInfo(): PositionsInfoResult {
  const { positionsInfoData, isLoading } = usePositionsStore()

  return { positionsInfoData, isLoading }
}
