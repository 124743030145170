import { Provider } from '@ethersproject/providers'
import EventEmitter from 'abis/EventEmitter.json'
import { getContract } from 'config/contracts'
import { IS_VERBOSE } from 'config/development'
import type {
  EventLogData,
  EventTxnParams,
} from 'context/SyntheticsEvents/types'
import { ethers } from 'ethers'
import { MutableRefObject } from 'react'

const DEPOSIT_CREATED_HASH = ethers.utils.id('DepositCreated')
const DEPOSIT_EXECUTED_HASH = ethers.utils.id('DepositExecuted')
const DEPOSIT_CANCELLED_HASH = ethers.utils.id('DepositCancelled')

const WITHDRAWAL_CREATED_HASH = ethers.utils.id('WithdrawalCreated')
const WITHDRAWAL_EXECUTED_HASH = ethers.utils.id('WithdrawalExecuted')
const WITHDRAWAL_CANCELLED_HASH = ethers.utils.id('WithdrawalCancelled')

const ORDER_CREATED_HASH = ethers.utils.id('OrderCreated')
const ORDER_EXECUTED_HASH = ethers.utils.id('OrderExecuted')
const ORDER_CANCELLED_HASH = ethers.utils.id('OrderCancelled')
const ORDER_UPDATED_HASH = ethers.utils.id('OrderUpdated')

const POSITION_INCREASE_HASH = ethers.utils.id('PositionIncrease')
const POSITION_DECREASE_HASH = ethers.utils.id('PositionDecrease')

export function subscribeToV2Events(
  chainId: number,
  provider: Provider,
  account: string,
  eventLogHandlers: MutableRefObject<
    Record<
      string,
      undefined | ((data: EventLogData, txnOpts: EventTxnParams) => void)
    >
  >,
) {
  const eventEmitter = new ethers.Contract(
    getContract(chainId, 'EventEmitter'),
    EventEmitter.abi,
    provider,
  )

  function handleEventLog(
    sender: string,
    eventName: string | number,
    eventNameHash: string,
    eventData: EventLogData,
    txnOpts: EventTxnParams,
  ) {
    eventLogHandlers.current[eventName]?.(parseEventLogData(eventData), txnOpts)
  }

  function handleEventLog1(
    sender: string,
    eventName: string | number,
    eventNameHash: string,
    topic1: string,
    eventData: EventLogData,
    txnOpts: EventTxnParams,
  ) {
    eventLogHandlers.current[eventName]?.(parseEventLogData(eventData), txnOpts)
  }

  function handleEventLog2(
    msgSender: string,
    eventName: string | number,
    eventNameHash: string,
    topic1: string,
    topic2: string,
    eventData: EventLogData,
    txnOpts: EventTxnParams,
  ) {
    eventLogHandlers.current[eventName]?.(parseEventLogData(eventData), txnOpts)
  }

  function handleCommonLog(e: {
    transactionHash: string
    blockNumber: number
    topics: string[]
    data: string
  }) {
    const txnOpts: EventTxnParams = {
      transactionHash: e.transactionHash,
      blockNumber: e.blockNumber,
    }

    try {
      const parsed = eventEmitter.interface.parseLog(e)

      if (!parsed) {
        throw new Error('Could not parse event')
      }
      if (parsed.name === 'EventLog') {
        handleEventLog(
          parsed.args[0],
          parsed.args[1],
          parsed.args[2],
          parsed.args[3],
          txnOpts,
        )
      } else if (parsed.name === 'EventLog1') {
        handleEventLog1(
          parsed.args[0],
          parsed.args[1],
          parsed.args[2],
          parsed.args[3],
          parsed.args[4],
          txnOpts,
        )
      } else if (parsed.name === 'EventLog2') {
        handleEventLog2(
          parsed.args[0],
          parsed.args[1],
          parsed.args[2],
          parsed.args[3],
          parsed.args[4],
          parsed.args[5],
          txnOpts,
        )
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error('error parsing event', e)
    }
  }

  const filters = createV2EventFilters(chainId, account, provider)

  filters.forEach((filter) => {
    provider.on(filter, handleCommonLog)
  })

  return () => {
    filters.forEach((filter) => {
      provider.off(filter, handleCommonLog)
    })
  }
}

function parseEventLogData(eventData: any): EventLogData {
  const ret: any = {}
  for (const typeKey of [
    'addressItems',
    'uintItems',
    'intItems',
    'boolItems',
    'bytes32Items',
    'bytesItems',
    'stringItems',
  ]) {
    ret[typeKey] = {}

    for (const listKey of ['items', 'arrayItems']) {
      ret[typeKey][listKey] = {}

      for (const item of eventData[typeKey][listKey]) {
        ret[typeKey][listKey][item.key] = item.value
      }
    }
  }
  return ret as EventLogData
}

function createV2EventFilters(
  chainId: number,
  account: string,
  wsProvider: Provider,
) {
  const abiCoder = ethers.utils.defaultAbiCoder
  const addressHash = abiCoder.encode(['address'], [account])
  const eventEmitter = new ethers.Contract(
    getContract(chainId, 'EventEmitter'),
    EventEmitter.abi,
    wsProvider,
  )
  const EVENT_LOG_TOPIC =
    eventEmitter.interface.getEventTopic('EventLog') ?? null
  const EVENT_LOG1_TOPIC =
    eventEmitter.interface.getEventTopic('EventLog1') ?? null
  const EVENT_LOG2_TOPIC =
    eventEmitter.interface.getEventTopic('EventLog2') ?? null

  return [
    // DEPOSITS AND WITHDRAWALS
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [
        EVENT_LOG2_TOPIC,
        [DEPOSIT_CREATED_HASH, WITHDRAWAL_CREATED_HASH],
        null,
        addressHash,
      ],
    },
    // NEW CONTRACTS
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [
        EVENT_LOG2_TOPIC,
        [DEPOSIT_CREATED_HASH, WITHDRAWAL_CREATED_HASH],
        null,
        addressHash,
      ],
    },
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [
        EVENT_LOG_TOPIC,
        [
          DEPOSIT_CANCELLED_HASH,
          DEPOSIT_EXECUTED_HASH,
          WITHDRAWAL_CANCELLED_HASH,
          WITHDRAWAL_EXECUTED_HASH,
        ],
      ],
    },
    // NEW CONTRACTS
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [
        EVENT_LOG2_TOPIC,
        [
          DEPOSIT_CANCELLED_HASH,
          DEPOSIT_EXECUTED_HASH,
          WITHDRAWAL_CANCELLED_HASH,
          WITHDRAWAL_EXECUTED_HASH,
        ],
        null,
        addressHash,
      ],
    },
    // ORDERS
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [EVENT_LOG2_TOPIC, ORDER_CREATED_HASH, null, addressHash],
    },
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [
        EVENT_LOG1_TOPIC,
        [ORDER_CANCELLED_HASH, ORDER_UPDATED_HASH, ORDER_EXECUTED_HASH],
      ],
    },
    // NEW CONTRACTS
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [
        EVENT_LOG2_TOPIC,
        [ORDER_CANCELLED_HASH, ORDER_UPDATED_HASH, ORDER_EXECUTED_HASH],
        null,
        addressHash,
      ],
    },
    // POSITIONS
    {
      address: getContract(chainId, 'EventEmitter'),
      topics: [
        EVENT_LOG1_TOPIC,
        [POSITION_INCREASE_HASH, POSITION_DECREASE_HASH],
        addressHash,
      ],
    },
  ]
}

export function getTotalSubscribersEventsCount(
  chainId: number,
  provider: Provider,
  { v2 }: { v2: boolean },
) {
  const v2Count = v2
    ? createV2EventFilters(chainId, ethers.constants.AddressZero, provider)
        .length
    : 0
  return v2Count
}
