'use client'
import { getChainName, getHighExecutionFee } from 'config/chains'
import { NATIVE_TOKEN_ADDRESS } from 'config/tokens'
import { DecreasePositionSwapType } from 'domain/synthetics/orders'
import {
  TokensData,
  convertToUsd,
  getTokenData,
} from 'domain/synthetics/tokens'
import { USD_DECIMALS } from 'gmx/lib/legacy'
import { applyFactor } from 'gmx/lib/numbers'
import { ExecutionFee, GasLimitsConfig } from '../types'
import { BigNumber } from 'ethers'
import { expandDecimals } from '@components/trade/gmx/tradingview/lib/numbers'

export function getExcessiveExecutionFee() {
  return 10
}

export function getExecutionFee(
  chainId: number,
  gasLimits: GasLimitsConfig,
  tokensData: TokensData,
  estimatedGasLimit: BigNumber,
  gasPrice: BigNumber,
  oraclePriceCount: BigNumber,
): ExecutionFee | undefined {
  const nativeToken = getTokenData(tokensData, NATIVE_TOKEN_ADDRESS)

  if (!nativeToken) {
    return undefined
  }

  // #region adjustGasLimitForEstimate. Copy from contract.
  let baseGasLimit = gasLimits.estimatedGasFeeBaseAmount
  baseGasLimit = baseGasLimit.add(
    gasLimits.estimatedGasFeePerOraclePrice.mul(oraclePriceCount),
  )
  const multiplierFactor = gasLimits.estimatedFeeMultiplierFactor
  const gasLimit = baseGasLimit.add(
    applyFactor(estimatedGasLimit, multiplierFactor),
  )
  // #endregion

  const feeTokenAmount = gasLimit.mul(gasPrice)

  const feeUsd = convertToUsd(
    feeTokenAmount,
    nativeToken.decimals,
    nativeToken.prices.minPrice,
  )!

  const isFeeHigh = feeUsd.gt(
    expandDecimals(getHighExecutionFee(chainId), USD_DECIMALS),
  )
  const isFeeVeryHigh = feeUsd.gt(
    expandDecimals(getExcessiveExecutionFee(), USD_DECIMALS),
  )

  const chainName = getChainName(chainId)
  const highWarning = `The network fees are high currently, which may be due to a temporary increase in transactions on the ${chainName} network.`
  const veryHighWarning = `The network fees are very high currently, which may be due to a temporary increase in transactions on the ${chainName} network.`

  const warning = isFeeVeryHigh
    ? veryHighWarning
    : isFeeHigh
      ? highWarning
      : undefined

  return {
    feeUsd,
    feeTokenAmount,
    feeToken: nativeToken,
    warning,
  }
}

/**
 * Only GM deposits. Do not confuse with increase with zero delta size.
 *
 * Copy from contract: `estimateExecuteDepositGasLimit`
 */
export function estimateExecuteDepositGasLimit(
  gasLimits: GasLimitsConfig,
  deposit: {
    // We do not use this yet
    longTokenSwapsCount?: number
    // We do not use this yet
    shortTokenSwapsCount?: number
    initialLongTokenAmount?: BigNumber
    initialShortTokenAmount?: BigNumber
    callbackGasLimit?: BigNumber
  },
) {
  const gasPerSwap = gasLimits.singleSwap
  const swapsCount = BigNumber.from(
    (deposit.longTokenSwapsCount ?? 0) + (deposit.shortTokenSwapsCount ?? 0),
  )
  const gasForSwaps = swapsCount.mul(gasPerSwap)

  if (
    deposit.initialLongTokenAmount === undefined ||
    deposit.initialLongTokenAmount.isZero() ||
    deposit.initialShortTokenAmount === undefined ||
    deposit.initialShortTokenAmount.isZero()
  ) {
    return gasLimits.depositSingleToken
      .add(deposit.callbackGasLimit ?? BigNumber.from(0))
      .add(gasForSwaps)
  }

  return gasLimits.depositMultiToken
    .add(deposit.callbackGasLimit ?? BigNumber.from(0))
    .add(gasForSwaps)
}

/**
 * Only GM withdrawals. Do not confuse with decrease with zero delta size.
 *
 * Copy from contract: `estimateExecuteWithdrawalGasLimit`
 */
export function estimateExecuteWithdrawalGasLimit(
  gasLimits: GasLimitsConfig,
  withdrawal: { callbackGasLimit?: BigNumber },
) {
  // Swap is not used but supported in the contract.
  // const gasPerSwap = gasLimits.singleSwap;
  // const swapsCount = 0n;
  // const gasForSwaps = swapsCount * gasPerSwap;

  return gasLimits.withdrawalMultiToken.add(
    withdrawal.callbackGasLimit ?? BigNumber.from(0),
  )
}

/**
 * Copy from contract: `estimateExecuteIncreaseOrderGasLimit`
 */
export function estimateExecuteIncreaseOrderGasLimit(
  gasLimits: GasLimitsConfig,
  order: { swapsCount?: number; callbackGasLimit?: BigNumber },
) {
  const gasPerSwap = gasLimits.singleSwap
  const swapsCount = BigNumber.from(order.swapsCount ?? 0)

  return gasLimits.increaseOrder
    .add(gasPerSwap.mul(swapsCount))
    .add(order.callbackGasLimit ?? BigNumber.from(0))
}

/**
 * Copy from contract: `estimateExecuteDecreaseOrderGasLimit`
 */
export function estimateExecuteDecreaseOrderGasLimit(
  gasLimits: GasLimitsConfig,
  order: {
    swapsCount: number
    callbackGasLimit?: BigNumber
    decreaseSwapType?: DecreasePositionSwapType
  },
) {
  const gasPerSwap = gasLimits.singleSwap
  let swapsCount = BigNumber.from(order.swapsCount)

  if (order.decreaseSwapType !== DecreasePositionSwapType.NoSwap) {
    swapsCount = swapsCount.add(1)
  }

  return gasLimits?.decreaseOrder
    .add(gasPerSwap.mul(swapsCount))
    .add(order.callbackGasLimit ?? 0)
}

export function estimateExecuteSwapOrderGasLimit(
  gasLimits: GasLimitsConfig,
  order: { swapsCount: number; callbackGasLimit?: BigNumber },
) {
  const gasPerSwap = gasLimits.singleSwap
  const swapsCount = BigNumber.from(order.swapsCount)

  return gasLimits.swapOrder
    .add(gasPerSwap.mul(swapsCount))
    .add(order.callbackGasLimit ?? 0)
}
