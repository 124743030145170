import ExchangeRouter from 'abis/ExchangeRouter.json'
import { getContract } from 'config/contracts'
import { Subaccount } from 'context/SubaccountContext/SubaccountContext'
import { Signer, ethers } from 'ethers'
import { getGasLimit } from 'gmx/lib/contracts'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { ReactNode } from 'react'
import { encodeFunctionData } from 'viem'
import {
  getSubaccountRouterContract,
  getSubaccountRouterParams,
} from '../subaccount/getSubaccountContract'

export type CancelOrderParams = {
  orderKeys: string[]
  setPendingTxns: (txns: PendingTransaction[]) => void
  detailsMsg?: ReactNode
  account: string
}

export async function cancelOrdersTxn(
  chainId: number,
  signer: Signer,
  subaccount: Subaccount,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  p: CancelOrderParams,
) {
  const router = subaccount
    ? getSubaccountRouterContract(chainId, subaccount.signer)
    : new ethers.Contract(
        getContract(chainId, 'ExchangeRouter'),
        ExchangeRouter.abi,
        signer,
      )

  const routerData = subaccount
    ? getSubaccountRouterParams(chainId)
    : {
        abi: ExchangeRouter.abi,
        address: getContract(chainId, 'ExchangeRouter'),
      }

  const payload = createCancelEncodedPayload({
    router,
    orderKeys: p.orderKeys,
  })

  const count = p.orderKeys.length

  const ordersText = count > 1 ? '# Orders' : 'Order'

  const callData = encodeFunctionData({
    abi: routerData.abi,
    args: [payload],
    functionName: 'multicall',
  })

  const gasLimit = await getGasLimit(router, 'multicall', [payload])

  const call = {
    address: routerData.address,
    calldata: callData,
    gas: BigInt(gasLimit.toString()),
  }

  const messageOpts = {
    sentMsg: `Cancelling ${ordersText}`,
    successMsg: `${ordersText} cancelled`,
    failMsg: `Failed to cancel ${ordersText}`,
    detailsMsg: p.detailsMsg,
  }

  return sendPaymasterTransaction({
    call,
    account: p.account,
    messageOpts,
    setPendingTxns: p.setPendingTxns,
    subaccount,
    router: router,
    payload: [payload],
    method: 'multicall',
  })
}

export function createCancelEncodedPayload({
  router,
  orderKeys = [],
}: {
  router: ethers.Contract
  orderKeys: (string | null)[]
}) {
  return orderKeys
    .filter(Boolean)
    .map((orderKey) =>
      router.interface.encodeFunctionData('cancelOrder', [orderKey]),
    )
}
