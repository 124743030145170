import ExchangeRouter from 'abis/ExchangeRouter.json'
import { getContract } from 'config/contracts'
import { Subaccount } from 'context/SubaccountContext/SubaccountContext'
import {
  getSubaccountRouterContract,
  getSubaccountRouterParams,
} from 'domain/synthetics/subaccount/getSubaccountContract'
import { convertToContractPrice } from 'domain/synthetics/tokens'
import { Token } from 'domain/tokens'
import { BigNumber, Signer, ethers } from 'ethers'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { encodeFunctionData } from 'viem'

export type UpdateOrderParams = {
  account: string
  orderKey: string
  indexToken?: Token
  sizeDeltaUsd: BigNumber
  triggerPrice: BigNumber
  acceptablePrice: BigNumber
  minOutputAmount: BigNumber
  executionFee?: BigNumber
  setPendingTxns: (txns: PendingTransaction[]) => void
}

export async function updateOrderTxn(
  chainId: number,
  signer: Signer,
  subaccount: Subaccount,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  p: UpdateOrderParams,
): Promise<void> {
  const {
    orderKey,
    sizeDeltaUsd,
    triggerPrice,
    acceptablePrice,
    minOutputAmount,
    executionFee,
    setPendingTxns,
    indexToken,
    account,
  } = p

  const router = subaccount
    ? getSubaccountRouterContract(chainId, subaccount.signer)
    : new ethers.Contract(
        getContract(chainId, 'ExchangeRouter'),
        ExchangeRouter.abi,
        signer,
      )

  const routerData = subaccount
    ? getSubaccountRouterParams(chainId)
    : {
        abi: ExchangeRouter.abi,
        address: getContract(chainId, 'ExchangeRouter'),
      }
  const encodedPayload = createUpdateEncodedPayload({
    chainId,
    router,
    orderKey,
    sizeDeltaUsd,
    executionFee,
    indexToken,
    acceptablePrice,
    triggerPrice,
    minOutputAmount,
  })

  const calldata = encodeFunctionData({
    abi: routerData.abi,
    args: [encodedPayload],
    functionName: 'multicall',
  })

  // const gasLimit = await getGasLimit(
  //   router,
  //   'multicall',
  //   [encodedPayload],
  //   executionFee != undefined && executionFee.gt(0) ? executionFee : undefined,
  // )

  const call: Parameters<typeof sendPaymasterTransaction>[0]['call'] = {
    address: getContract(chainId, 'ExchangeRouter'),
    calldata,
    value:
      executionFee != undefined && executionFee.gt(0)
        ? executionFee.toBigInt()
        : undefined,
  }

  const messageOpts = {
    sentMsg: `Updating order`,
    successMsg: `Update order executed`,
    failMsg: `Failed to update order`,
    showPreliminaryMsg: Boolean(subaccount),
  }

  await sendPaymasterTransaction({
    call,
    account: subaccount ? subaccount.address : account,
    messageOpts,
    setPendingTxns,
    subaccount,
    router: router,
    payload: [encodedPayload],
    method: 'multicall',
  })
}

export function createUpdateEncodedPayload({
  chainId,
  router,
  orderKey,
  sizeDeltaUsd,
  executionFee,
  indexToken,
  acceptablePrice,
  triggerPrice,
  minOutputAmount,
}: {
  chainId: number
  router: ethers.Contract
  orderKey: string
  sizeDeltaUsd: BigNumber
  executionFee?: BigNumber
  indexToken?: Token
  acceptablePrice: BigNumber
  triggerPrice: BigNumber
  minOutputAmount: BigNumber
}) {
  const orderVaultAddress = getContract(chainId, 'OrderVault')

  const multicall = []
  if (executionFee != undefined && executionFee.gt(0)) {
    multicall.push({
      method: 'sendWnt',
      params: [orderVaultAddress, executionFee],
    })
  }

  multicall.push({
    method: 'updateOrder',
    params: [
      orderKey,
      sizeDeltaUsd,
      convertToContractPrice(acceptablePrice, indexToken?.decimals || 0),
      convertToContractPrice(triggerPrice, indexToken?.decimals || 0),
      minOutputAmount,
      false, // autoCancel
    ],
  })

  return multicall
    .filter(Boolean)
    .map((call) =>
      router.interface.encodeFunctionData(call!.method, call!.params),
    )
}
