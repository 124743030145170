import { useEffect } from 'react'
import { toast } from 'react-toastify'

export const useToastAutoClose = (
  isCompleted: boolean,
  toastTimestamp: number,
) => {
  useEffect(
    function autoClose() {
      let timerId: NodeJS.Timeout

      if (isCompleted) {
        toast.update(toastTimestamp, {
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
        })
      }

      return () => {
        clearTimeout(timerId)
      }
    },
    [isCompleted, toastTimestamp],
  )
}
