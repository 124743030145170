import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { BigNumber } from 'ethers'
import { getActionName } from './utils'
import { ENABLE_LOGGING } from 'utils/constants'
import { PendingTransaction } from 'hooks/usePaymaster'
import { initilaFilterData } from '../config/earn'

export type TokenPriceInfo = {
  minPrice: BigNumber
  maxPrice: BigNumber
}

export type CommonStoreType = {
  isUserAddressChecked: boolean
  setIsUserAddressChecked: (
    isUserLoggedIn: CommonStoreType['isUserAddressChecked'],
  ) => void
  resetUserAddressCheck: () => void
  marketInfoStore: Record<string, any> | null
  setMarketInfoStore: (
    marketInfoStore: CommonStoreType['marketInfoStore'],
  ) => void
  marketStore: Record<string, any> | null
  setMarketStore: (marketStore: CommonStoreType['marketStore']) => void
  sortedMarketsWithIndexTokenStore: Record<string, any> | null
  setSortedMarketsWithIndexTokenStore: (
    sortedMarketsWithIndexTokenStore: CommonStoreType['sortedMarketsWithIndexTokenStore'],
  ) => void
  prices: Record<string, TokenPriceInfo>
  setPrices: (prices: CommonStoreType['prices']) => void
  showGlobalSearchModal: boolean
  onShowGlobalSearchModal: () => void
  onCloseGlobalSearchModal: () => void
  globalSearchQuery: string
  setGlobalSearchQuery: (globalSearchQuery: string) => void
  pendingTxns: PendingTransaction[]
  setPendingTxns: (pendingTxns: PendingTransaction[]) => void
  setTokenPrice: (tokenAddress: string, price: TokenPriceInfo) => void
  setPricesUsingApi: (prices: CommonStoreType['prices']) => void
  isWSBlocked: boolean
  setIsWSBlocked: (isWSBlocked: boolean) => void
  appliedFilter: string
  setAppliedFilter: (appliedFilter: CommonStoreType['appliedFilter']) => void
}
const STORE_NAME = 'common'

const commonStore = create<CommonStoreType>()(
  devtools(
    (set) => ({
      isUserAddressChecked: false,
      setIsUserAddressChecked(isUserAddressChecked) {
        set(
          { isUserAddressChecked },
          false,
          getActionName(STORE_NAME, 'setIsUserAddressChecked'),
        )
      },
      resetUserAddressCheck() {
        set(
          { isUserAddressChecked: false },
          false,
          getActionName(STORE_NAME, 'resetUserAddressCheck'),
        )
      },
      marketInfoStore: null,
      setMarketInfoStore(marketInfoStore) {
        set(
          { marketInfoStore },
          false,
          getActionName(STORE_NAME, 'setMarketInfoStore'),
        )
      },
      marketStore: null,
      setMarketStore(marketStore) {
        set({ marketStore }, false, getActionName(STORE_NAME, 'setMarketStore'))
      },
      sortedMarketsWithIndexTokenStore: null,
      setSortedMarketsWithIndexTokenStore(sortedMarketsWithIndexTokenStore) {
        set(
          { sortedMarketsWithIndexTokenStore },
          false,
          getActionName(STORE_NAME, 'setStoredMarketsWithIndexTokenStore'),
        )
      },
      prices: {},
      setPrices(prices) {
        set({ prices }, false, getActionName(STORE_NAME, 'setPrices'))
      },
      setTokenPrice(tokenAddress, priceInfo) {
        set(
          ({ prices }) => ({
            prices: {
              ...prices,
              [tokenAddress]: priceInfo,
            },
          }),
          false,
          getActionName(STORE_NAME, 'setTokenPrice'),
        )
      },
      isWSBlocked: true,
      setIsWSBlocked(isWSBlocked) {
        set({ isWSBlocked }, false, getActionName(STORE_NAME, 'setIsWSBlocked'))
      },
      showGlobalSearchModal: false,
      onShowGlobalSearchModal() {
        set(
          { showGlobalSearchModal: true },
          false,
          getActionName(STORE_NAME, 'onShowGlobalSearchModal'),
        )
      },
      onCloseGlobalSearchModal() {
        set(
          { showGlobalSearchModal: false },
          false,
          getActionName(STORE_NAME, 'onCloseGlobalSearchModal'),
        )
      },
      globalSearchQuery: '',
      setGlobalSearchQuery(globalSearchQuery) {
        set(
          { globalSearchQuery },
          false,
          getActionName(STORE_NAME, 'setGlobalSearchQuery'),
        )
      },
      pendingTxns: [],
      setPendingTxns(pendingTxns) {
        set({ pendingTxns }, false, getActionName(STORE_NAME, 'setPendingTxns'))
      },
      setPricesUsingApi(pricesFromApi) {
        set(
          ({ prices }) => ({
            prices: {
              ...prices,
              ...pricesFromApi,
            },
          }),
          false,
          getActionName(STORE_NAME, 'setPricesUsingApi'),
        )
      },
      appliedFilter: initilaFilterData['ALL'].label,
      setAppliedFilter(appliedFilter) {
        set(
          { appliedFilter },
          false,
          getActionName(STORE_NAME, 'setAppliedFilter'),
        )
      },
    }),
    { enabled: ENABLE_LOGGING, name: 'common' },
  ),
)

export default commonStore
