import ExchangeRouter from 'abis/ExchangeRouter.json'
import { getContract } from 'config/contracts'
import { NATIVE_TOKEN_ADDRESS, convertTokenAddress } from 'config/tokens'
import { Subaccount } from 'context/SubaccountContext/SubaccountContext'
import { PendingOrderData, SetPendingOrder } from 'context/SyntheticsEvents'
import { BigNumber, Signer, ethers } from 'ethers'
import { getGasLimit } from 'gmx/lib/contracts'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { encodeFunctionData } from 'viem'
import {
  getSubaccountRouterContract,
  getSubaccountRouterParams,
} from '../subaccount/getSubaccountContract'
import { TokensData } from '../tokens'
import { applySlippageToMinOut } from '../trade'
import { DecreasePositionSwapType, OrderType } from './types'
import { isMarketOrderType } from './utils'

export type SwapOrderParams = {
  account: string
  fromTokenAddress: string
  fromTokenAmount: BigNumber
  toTokenAddress: string
  swapPath: string[]
  referralCode?: string
  tokensData: TokensData
  minOutputAmount: BigNumber
  orderType: OrderType.MarketSwap | OrderType.LimitSwap
  executionFee: BigNumber
  allowedSlippage: number
  setPendingTxns: (txns: PendingTransaction[]) => void
  setPendingOrder: SetPendingOrder
}

export async function createSwapOrderTxn(
  chainId: number,
  signer: Signer,
  subaccount: Subaccount,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  p: SwapOrderParams,
) {
  const exchangeRouter = new ethers.Contract(
    getContract(chainId, 'ExchangeRouter'),
    ExchangeRouter.abi,
    signer,
  )
  const isNativePayment = p.fromTokenAddress === NATIVE_TOKEN_ADDRESS
  const isNativeReceive = p.toTokenAddress === NATIVE_TOKEN_ADDRESS
  subaccount = isNativePayment ? null : subaccount
  const router = subaccount
    ? getSubaccountRouterContract(chainId, subaccount.signer)
    : exchangeRouter

  const routerData = subaccount
    ? getSubaccountRouterParams(chainId)
    : {
        abi: ExchangeRouter.abi,
        address: getContract(chainId, 'ExchangeRouter'),
      }

  const { encodedPayload, totalWntAmount, minOutputAmount } = await getParams(
    router,
    signer,
    subaccount,
    chainId,
    p,
  )

  const initialCollateralTokenAddress = convertTokenAddress(
    chainId,
    p.fromTokenAddress,
    'wrapped',
  )

  const swapOrder: PendingOrderData = {
    account: p.account,
    marketAddress: ethers.constants.AddressZero,
    initialCollateralTokenAddress,
    initialCollateralDeltaAmount: p.fromTokenAmount,
    swapPath: p.swapPath,
    sizeDeltaUsd: BigNumber.from(0),
    minOutputAmount,
    isLong: false,
    orderType: p.orderType,
    shouldUnwrapNativeToken: isNativeReceive,
    txnType: 'create',
  }

  p.setPendingOrder(swapOrder)

  const calldata = encodeFunctionData({
    abi: routerData?.abi,
    args: [encodedPayload],
    functionName: 'multicall',
  })

  const gasLimit = await getGasLimit(
    router,
    'multicall',
    [encodedPayload],
    totalWntAmount,
  )

  const call = {
    address: routerData?.address,
    calldata,
    gas: BigInt(gasLimit.toString()),
    value: totalWntAmount.toBigInt(),
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }

  return sendPaymasterTransaction({
    call,
    account: p.account,
    messageOpts,
    setPendingTxns: p.setPendingTxns,
    subaccount,
    router: router,
    payload: [encodedPayload],
    method: 'multicall',
  })
}

async function getParams(
  router: ethers.Contract,
  signer: Signer,
  subaccount: Subaccount,
  chainId: number,
  p: SwapOrderParams,
) {
  const isNativePayment = p.fromTokenAddress === NATIVE_TOKEN_ADDRESS
  const isNativeReceive = p.toTokenAddress === NATIVE_TOKEN_ADDRESS
  const orderVaultAddress = getContract(chainId, 'OrderVault')
  const wntSwapAmount = isNativePayment ? p.fromTokenAmount : BigNumber.from(0)
  const totalWntAmount = wntSwapAmount.add(p.executionFee)

  const initialCollateralTokenAddress = convertTokenAddress(
    chainId,
    p.fromTokenAddress,
    'wrapped',
  )

  const shouldApplySlippage = isMarketOrderType(p.orderType)

  const minOutputAmount = shouldApplySlippage
    ? applySlippageToMinOut(p.allowedSlippage, p.minOutputAmount)
    : p.minOutputAmount

  const initialCollateralDeltaAmount = subaccount
    ? p.fromTokenAmount
    : BigNumber.from(0)

  const createOrderParams = {
    addresses: {
      receiver: p.account,
      cancellationReceiver: ethers.constants.AddressZero,
      initialCollateralToken: initialCollateralTokenAddress,
      callbackContract: ethers.constants.AddressZero,
      market: ethers.constants.AddressZero,
      swapPath: p.swapPath,
      uiFeeReceiver: ethers.constants.AddressZero,
    },
    numbers: {
      sizeDeltaUsd: BigNumber.from(0),
      initialCollateralDeltaAmount,
      triggerPrice: BigNumber.from(0),
      acceptablePrice: BigNumber.from(0),
      executionFee: p.executionFee,
      callbackGasLimit: BigNumber.from(0),
      minOutputAmount,
    },
    autoCancel: false,
    orderType: p.orderType,
    decreasePositionSwapType: DecreasePositionSwapType.NoSwap,
    isLong: false,
    shouldUnwrapNativeToken: isNativeReceive,
    referralCode: p.referralCode || ethers.constants.HashZero,
  }

  const multicall = [
    { method: 'sendWnt', params: [orderVaultAddress, totalWntAmount] },

    !isNativePayment && !subaccount
      ? {
          method: 'sendTokens',
          params: [p.fromTokenAddress, orderVaultAddress, p.fromTokenAmount],
        }
      : undefined,

    {
      method: 'createOrder',
      params: subaccount
        ? [await signer.getAddress(), createOrderParams]
        : [createOrderParams],
    },
  ]

  return {
    minOutputAmount,
    totalWntAmount,
    encodedPayload: multicall
      .filter(Boolean)
      .map((call) =>
        router.interface.encodeFunctionData(call!.method, call!.params),
      ),
  }
}
