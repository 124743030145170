import { CLOUD_FRONT_URL } from 'config/constants'
import { MultiAssetPair } from 'config/MultiAssetPair'
import { useViewport } from 'hooks/useViewport'
import Image from 'next/image'
import React from 'react'

export type tokenState = 'default' | 'hover' | 'active'

interface RenderTokenIconProps {
  symbol?: string
  size?: 'smallest' | 'small' | 'regular' | 'large' | 'largest'
  state?: tokenState
}

export const getTokenUrl = (token: string, state: tokenState) =>
  `${CLOUD_FRONT_URL}/tokens/${token}${state === 'active' ? '-white' : state === 'hover' ? '-hover' : ''}.svg`

const RenderTokenIcon: React.FC<RenderTokenIconProps> = ({
  symbol,
  size = 'regular',
  state = 'active',
}) => {
  const { isMobile } = useViewport()

  if (!symbol) {
    return null
  }

  const isMultiAssetPair = MultiAssetPair.includes(symbol.toLowerCase())

  const dimensions = {
    smallest: 16,
    small: isMultiAssetPair ? 16 : 20,
    regular: isMultiAssetPair ? 20 : 24,
    large: isMultiAssetPair ? (isMobile ? 20 : 24) : isMobile ? 24 : 32,
    largest: isMultiAssetPair ? (isMobile ? 20 : 26) : isMobile ? 28 : 40,
  }[size]

  if (isMultiAssetPair) {
    const [token1, token2] = symbol.toLowerCase().split('/')
    return (
      <div className="isolate min-w-fit">
        <Image
          alt={token1}
          className="relative z-20"
          src={getTokenUrl(token1, state)}
          width={dimensions}
          height={dimensions}
        />
        <Image
          alt={token2}
          className="-mt-2 ml-2"
          src={getTokenUrl(token2, state)}
          width={dimensions}
          height={dimensions}
        />
      </div>
    )
  }
  return (
    <Image
      alt={symbol}
      src={getTokenUrl(symbol.toLowerCase(), state)}
      width={dimensions}
      height={dimensions}
    />
  )
}

export default RenderTokenIcon
