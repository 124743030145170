import { WS_LOST_FOCUS_TIMEOUT } from 'config/ui'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import useIsWindowVisible from './useIsWindowVisible'

export const TRADE_LOST_FOCUS_TIMEOUT = 1000 * 60

function useHasLostFocusHelper(p: {
  timeout: number
  checkIsTabFocused?: boolean
  whiteListedPages?: string[]
  debugId?: string
}) {
  const { whiteListedPages, checkIsTabFocused, timeout } = p

  const isWindowVisible = useIsWindowVisible()
  const { pathname } = useRouter()

  const isFocused = useMemo(() => {
    const checks: boolean[] = []

    if (checkIsTabFocused) {
      checks.push(isWindowVisible)
    }

    if (whiteListedPages?.length) {
      checks.push(
        whiteListedPages.some((whitelistedPart) =>
          pathname.startsWith(whitelistedPart),
        ),
      )
    }

    return checks.every(Boolean)
  }, [checkIsTabFocused, isWindowVisible, pathname, whiteListedPages])

  const [hasLostFocus, setHasLostFocus] = useState(!isFocused)

  const timerId = useRef<any>()
  const lostFocusTime = useRef<number>()

  useEffect(() => {
    if (!isFocused && !hasLostFocus) {
      lostFocusTime.current = Date.now()
      timerId.current = setTimeout(() => {
        if (
          lostFocusTime.current &&
          Date.now() - lostFocusTime.current >= timeout
        ) {
          setHasLostFocus(true)
        }
      }, timeout)
    } else {
      lostFocusTime.current = undefined
      clearTimeout(timerId.current)

      if (isFocused) {
        setHasLostFocus(false)
      }
    }

    return () => {
      clearTimeout(timerId.current)
    }
  }, [hasLostFocus, isFocused, timeout])

  return hasLostFocus
}

export function useHasLostFocus() {
  const hasPageLostFocus = useHasLostFocusHelper({
    timeout: WS_LOST_FOCUS_TIMEOUT,
    checkIsTabFocused: true,
    debugId: 'Tab',
  })

  const v1WhiteListedPages = useMemo(() => ['/v1'], [])

  const hasV1LostFocus = useHasLostFocusHelper({
    timeout: TRADE_LOST_FOCUS_TIMEOUT,
    whiteListedPages: v1WhiteListedPages,
    debugId: 'V1 Events',
  })

  const v2WhiteListedPages = useMemo(() => ['/trade', '/v2', '/pools'], [])

  const hasV2LostFocus = useHasLostFocusHelper({
    timeout: TRADE_LOST_FOCUS_TIMEOUT,
    whiteListedPages: v2WhiteListedPages,
    debugId: 'V2 Events',
  })

  return {
    hasPageLostFocus,
    hasV1LostFocus: hasV1LostFocus || hasPageLostFocus,
    hasV2LostFocus: hasV2LostFocus || hasPageLostFocus,
  }
}
