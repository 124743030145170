import CustomErrors from 'abis/CustomErrors.json'
import { IS_VERBOSE } from 'config/development'
import { ethers } from 'ethers'
import {
  PendingDepositData,
  PendingOrderData,
  PendingWithdrawalData,
} from './types'

export function getPendingOrderKey(data: Omit<PendingOrderData, 'txnType'>) {
  return [
    data.account,
    data.marketAddress,
    data.initialCollateralTokenAddress,
    data.swapPath.join('-'),
    data.shouldUnwrapNativeToken,
    data.isLong,
    data.orderType,
    data.sizeDeltaUsd.toString(),
    data.initialCollateralDeltaAmount.toString(),
  ].join(':')
}

export function getPendingDepositKey(data: PendingDepositData) {
  if (data.initialShortTokenAddress === data.initialLongTokenAddress) {
    return [
      data.account,
      data.marketAddress,
      data.initialLongTokenAddress,
      data.longTokenSwapPath.join('-'),
      data.shouldUnwrapNativeToken,
      data.initialLongTokenAmount.add(data.initialShortTokenAmount).toString(),
    ].join(':')
  }

  return [
    data.account,
    data.marketAddress,
    data.initialLongTokenAddress,
    data.initialShortTokenAddress,
    data.longTokenSwapPath.join('-'),
    data.shortTokenSwapPath.join('-'),
    data.shouldUnwrapNativeToken,
    data.initialLongTokenAmount.toString(),
    data.initialShortTokenAmount.toString(),
  ].join(':')
}

export function getPendingWithdrawalKey(data: PendingWithdrawalData) {
  return [
    data.account,
    data.marketAddress,
    data.minLongTokenAmount.toString(),
    data.marketTokenAmount.toString(),
    data.shouldUnwrapNativeToken,
  ].join(':')
}
export async function getRevertReasonBytes({
  provider,
  txHash,
}: {
  provider: ethers.providers.JsonRpcProvider
  txHash: string
}) {
  try {
    // Get the transaction

    const tx = await provider.getTransaction(txHash)

    if (!tx) {
      throw new Error('Transaction not found')
    }

    // Make a call to the blockchain as it would have been at the block number the transaction was included
    const code = await provider.call(tx as any, tx.blockNumber)

    // If the call succeeds, there is no revert reason
    if (code === '0x') {
      return 'Transaction did not revert'
    }

    // Decode the revert reason
    return code
  } catch (error: any) {
    // Decode the error data
    if (error.data) {
      const errorData = error.data
      // The error data is in the form: 'Reverted 0x[HEX DATA]'
      const revertData = errorData.slice(errorData.indexOf('0x') + 2)
      const decoded = ethers.utils.toUtf8String('0x' + revertData.slice(8))
      return decoded
    } else {
      return 'Unknown error: ' + error.message
    }
  }
}

export function parseErrorBytes(bytes: string) {
  const customErrors = new ethers.Contract(
    ethers.constants.AddressZero,
    CustomErrors.abi,
  )
  let error: ReturnType<typeof customErrors.interface.parseError> | null = null
  try {
    error = customErrors.interface.parseError(bytes)
  } catch (err) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.error('Error parsing error bytes', err)
  }
  return error
}
