import mangoStore from '@store/mangoStore'
import { useMemo } from 'react'
import { breakpoints } from 'utils/theme'

/**
 *
 * @deprecated use useBreakpoints
 */
export const useViewport = () => {
  const width = mangoStore((s) => s.window.width)
  const height = mangoStore((s) => s.window.height)

  const [isMobile, isTablet, isDesktop] = useMemo(() => {
    if (!width) {
      return [false, false, false]
    }
    const mobile = width < breakpoints.md
    const tablet = width >= breakpoints.md && width < breakpoints.lg
    const desktop = width >= breakpoints.lg
    return [mobile, tablet, desktop]
  }, [width])

  return { width, height, isMobile, isTablet, isDesktop }
}
