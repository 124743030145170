import { MarketsData } from './types'
import { useMarketsStore } from '@store/marketsStore'

export type MarketsResult = {
  marketsData?: MarketsData
  marketsAddresses?: string[]
}

export function useMarkets(): MarketsResult {
  const data = useMarketsStore((state) => state)

  return {
    marketsData: data?.marketsData,
    marketsAddresses: Object.keys(data.marketsData || {}),
  }
}
