import CustomErrors from 'abis/CustomErrors.json'
import DataStore from 'abis/DataStore.json'
import ExchangeRouter from 'abis/ExchangeRouter.json'
import { ToastifyDebug } from 'components/ToastifyDebug/ToastifyDebug'
import { getContract } from 'config/contracts'
import { NONCE_KEY, orderKey } from 'config/dataStore'
import { IS_VERBOSE } from 'config/development'
import { convertTokenAddress } from 'config/tokens'
import {
  TokenPrices,
  TokensData,
  convertToContractPrice,
  getTokenData,
} from 'domain/synthetics/tokens'
import { BigNumber, ethers } from 'ethers'
import { getErrorMessage } from 'gmx/lib/contracts/transactionErrors'
import { helperToast } from 'gmx/lib/helperToast'
import { getProvider } from 'gmx/lib/rpc'

export type MulticallRequest = { method: string; params: any[] }[]

export type PriceOverrides = {
  [address: string]: TokenPrices | undefined
}

type SimulateExecuteOrderParams = {
  account: string
  createOrderMulticallPayload: string[]
  primaryPriceOverrides: PriceOverrides
  secondaryPriceOverrides: PriceOverrides
  signer?: any
  tokensData: TokensData
  value: BigNumber
  method?: string
  errorTitle?: string
  extraArgs?: any[]
  pricesUpdatedAt?: any
}

export async function simulateExecuteOrderTxn(
  chainId: number,
  p: SimulateExecuteOrderParams,
) {
  const dataStoreAddress = getContract(chainId, 'DataStore')
  const dataStore = new ethers.Contract(
    dataStoreAddress,
    DataStore.abi,
    p.signer,
  )
  const exchangeRouter = new ethers.Contract(
    getContract(chainId, 'ExchangeRouter'),
    ExchangeRouter.abi,
    p.signer,
  )

  const provider = getProvider(undefined, chainId) as any

  const block = await provider?.getBlock('latest')
  if (!block) {
    throw new Error("block can't be fetched")
  }
  const blockNumber = block.number
  const nonce = await dataStore.getUint(NONCE_KEY, { blockTag: blockNumber })
  const nextNonce = BigNumber.from(nonce).add(1)
  const nextKey = orderKey(dataStoreAddress, nextNonce)

  const { primaryTokens, primaryPrices } = getSimulationPrices(
    chainId,
    p.tokensData,
    p.primaryPriceOverrides,
    p.secondaryPriceOverrides,
  )
  const priceTimestamp = block.timestamp + 5
  const method = p.method || 'simulateExecuteOrder'

  const simulationPayload = [
    ...p.createOrderMulticallPayload,
    exchangeRouter.interface.encodeFunctionData(method, [
      nextKey,
      {
        primaryTokens: primaryTokens,
        primaryPrices: primaryPrices,
        minTimestamp: p.pricesUpdatedAt || priceTimestamp,
        maxTimestamp: p.pricesUpdatedAt || priceTimestamp,
      },
      ...(p.extraArgs ?? []),
    ]),
  ]

  const errorTitle = p.errorTitle || `Execute order simulation failed.`

  try {
    await exchangeRouter.callStatic.multicall(simulationPayload, {
      value: p.value,
      blockTag: blockNumber,
      from: p.account,
    })
  } catch (txnError: any) {
    const customErrors = new ethers.Contract(
      ethers.constants.AddressZero,
      CustomErrors.abi,
    )

    let msg: any = undefined

    try {
      const errorData =
        extractDataFromError(txnError?.info?.error?.message) ??
        extractDataFromError(txnError?.message)
      if (!errorData) {
        throw new Error('No data found in error.')
      }

      const parsedError = customErrors.interface.parseError(errorData)
      const isSimulationPassed = parsedError?.name === 'EndOfOracleSimulation'

      if (isSimulationPassed) {
        return
      }

      const parsedArgs = Object.keys(parsedError?.args ?? []).reduce(
        (acc: any, k) => {
          if (!Number.isNaN(Number(k))) {
            return acc
          }
          acc[k] = parsedError?.args[k].toString()
          return acc
        },
        {},
      )

      msg = (
        <div>
          {parsedError?.name || errorTitle}
          <br />
          <br />
          <ToastifyDebug>
            {`${txnError?.info?.error?.message ?? parsedError?.name ?? txnError?.message} ${JSON.stringify(parsedArgs, null, 2)}`}
          </ToastifyDebug>
        </div>
      )
    } catch (parsingError) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error(parsingError)

      const commonError = getErrorMessage(chainId, txnError, errorTitle)
      msg = commonError.failMsg
    }

    if (!msg) {
      msg = (
        <div>
          <>Execute order simulation failed.</>
          <br />
          <br />
          <ToastifyDebug>{`Unknown Error`}</ToastifyDebug>
        </div>
      )
    }

    helperToast.error(msg)

    throw txnError
  }
}

export function extractDataFromError(errorMessage: unknown) {
  if (typeof errorMessage !== 'string') {
    return null
  }

  const pattern = /data="([^"]+)"/
  const match = errorMessage.match(pattern)

  if (match && match[1]) {
    return match[1]
  }
  return null
}

function getSimulationPrices(
  chainId: number,
  tokensData: TokensData,
  primaryPricesMap: PriceOverrides,
  secondaryPricesMap: PriceOverrides,
) {
  const tokenAddresses = Object.keys(tokensData)

  const primaryTokens: string[] = []
  const primaryPrices: { min: BigNumber; max: BigNumber }[] = []
  const secondaryPrices: { min: BigNumber; max: BigNumber }[] = []

  for (const address of tokenAddresses) {
    const token = getTokenData(tokensData, address)
    const convertedAddress = convertTokenAddress(chainId, address, 'wrapped')

    if (!token?.prices || primaryTokens.includes(convertedAddress)) {
      continue
    }

    primaryTokens.push(convertedAddress)

    const currentPrice = {
      min: convertToContractPrice(token.prices.minPrice, token.decimals),
      max: convertToContractPrice(token.prices.maxPrice, token.decimals),
    }

    const primaryOverridedPrice = primaryPricesMap[address]

    if (primaryOverridedPrice) {
      primaryPrices.push({
        min: convertToContractPrice(
          primaryOverridedPrice.minPrice,
          token.decimals,
        ),
        max: convertToContractPrice(
          primaryOverridedPrice.maxPrice,
          token.decimals,
        ),
      })
    } else {
      primaryPrices.push(currentPrice)
    }

    const secondaryOverridedPrice = secondaryPricesMap[address]

    if (secondaryOverridedPrice) {
      secondaryPrices.push({
        min: convertToContractPrice(
          secondaryOverridedPrice.minPrice,
          token.decimals,
        ),
        max: convertToContractPrice(
          secondaryOverridedPrice.maxPrice,
          token.decimals,
        ),
      })
    } else {
      secondaryPrices.push(currentPrice)
    }
  }
  return {
    primaryTokens,
    secondaryTokens: primaryTokens,
    primaryPrices,
    secondaryPrices,
  }
}
